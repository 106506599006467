[data-words] {
  overflow: hidden;
  width: 450px;
  height: 80px;

  @media (max-width: 1604px) {
    height: 65px;
  }

  @media (max-width: 1399px) {
    height: 50px;
  }

  @media (max-width: 1023px) {
    height: 65px;
  }

  &:after {
    display: none;
  }
}

[data-words-2] {
  display: inline-block;
  overflow: hidden;
  width: 500px;
  height: 35px;
  transform: translateY(8px);

  @media (max-width: 999px) {
    transform: translateY(11px);
  }

  &:after {
    display: none;
  }
}

.text-anim-items {
  position: relative;
}

.text-anim-item {
  white-space: nowrap;
  position: absolute;
  left: 0;
  top: 0;
  color: $white;
  transform: translate3d(0, -120%, 0);

  &.anim-in {
    transform: translate3d(-50%, -120%, 0);
    animation: textAnimIn 0.6s 0.3s forwards;

    @media (min-width: 768px) {
      transform: translate3d(0, -120%, 0);
      animation: textAnimIn 0.6s 0.3s forwards;
    }
  }

  &.anim-out {
    transform: translate3d(-50%, 0%, 0);
    animation: textAnimOut 0.6s forwards;

    @media (min-width: 768px) {
      transform: translate3d(0, 0%, 0);
      animation: textAnimOut 0.6s forwards;
    }
  }
}

@keyframes textAnimIn {
  0% {
    transform: translate3d(0, -120%, 0);
  }
  100% {
    transform: translate3d(0, 0%, 0);
  }
}

@keyframes textAnimOut {
  0% {
    transform: translate3d(0, 0%, 0);
  }
  50% {
    transform: translate3d(0, -20%, 0);
  }
  100% {
    transform: translate3d(0, 120%, 0);
  }
}

@keyframes textAnimInCenter {
  0% {
    transform: translate3d(-50%, -120%, 0);
  }
  100% {
    transform: translate3d(-50%, 10%, 0);
  }
}

@keyframes textAnimOutCenter {
  0% {
    transform: translate3d(-50%, 0%, 0);
  }
  50% {
    transform: translate3d(-50%, -20%, 0);
  }
  100% {
    transform: translate3d(-50%, 120%, 0);
  }
}

.banner-fixed {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 25;
  display: none;
  background: $dark;

  &.is-active {
    display: block;
  }

  .banner-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 20px;

    > span {
      margin-right: 40px;
      font-size: 22px;
      font-weight: 600;
      color: $white;

      @media (max-width: 1023px) {
        font-size: 20px
      }

      @media (max-width: 767px) {
        font-size: 18px
      }
    }
  }
}

.home-hero {
  width: 100%;
  height: auto;
  background: $dark;

  @media (max-width: 1023px) {
    height: auto;
  }

  .hero-container {
    position: relative;
    display: flex;
    align-items: flex-end;
    padding-top: 25px;

    @media (max-width: 1023px) {
      padding-top: 0;
      flex-direction: column-reverse;
    }
  }

  .hero-video {
    width: 70%;
    margin-left: auto;

    @media (max-width: 1023px) {
      width: 100%;
    }

    iframe {
      width: 100%;

      @media (max-width: 1023px) {
        height: 500px;
      }

      @media (max-width: 699px) {
        height: 400px;
      }

      @media (max-width: 499px) {
        height: 250px;
      }
    }
  }

  .hero-content {
    position: absolute;
    top: 50%;
    left: 5%;
    z-index: 1;
    width: 47%;
    padding: 20px 40px;
    background: $dark;
    transform: translateY(-50%);

    @media (max-width: 1349px) {
      width: 60%;
    }

    @media (max-width: 1023px) {
      position: relative;
      top: auto;
      left: auto;
      order: 1;
      width: 100%;
      margin: 0 auto;
      padding: 40px 20px;
      transform: none;
    }
  }

  .hero-title {
    margin: 0 0 30px;
    padding: 0;
    font-size: 62px;
    line-height: 100%;
    color: $white;
    font-family: $fontTitle;

    @media (max-width: 1604px) {
      font-size: 52px;
    }

    @media (max-width: 1399px) {
      font-size: 40px;
    }

    @media (max-width: 1023px) {
      margin: 0 0 25px;
      font-size: 52px;
    }

    b,
    strong{
      font-weight: inherit;
    }

    p {
      margin: 0;
    }
  }

  .hero-text {
    font-size: 26px;
    line-height: 120%;
    font-weight: 400;
    color: $white;

    @media (max-width: 1399px) {
      font-size: 24px;
    }

    @media (max-width: 1023px) {
      font-size: 22px;
    }
  }

  .hero-cta-wrapper {
    display: flex;
    align-items: center;
    margin-top: 50px;

    //@media (max-width: 599px) {
    //  flex-direction: column;
    //  align-items: flex-start;
    //}
  }

  .cta-label {
    margin-right: 30px;
    font-size: 26px;
    line-height: 130%;
    font-weight: 600;
    color: $white;

    @media (max-width: 1399px) {
      font-size: 24px;
    }

    @media (max-width: 1023px) {
      font-size: 22px;
    }

    //@media (max-width: 599px) {
    //  margin-bottom: 15px;
    //}
  }
}

.home-image-text {
  width: 100%;
  padding-top: 50px;
  background: $dark;

  .image-text-container {
    position: relative;
    height: 900px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    @media (max-width: 899px) {
      height: 600px;
    }
  }

  .image-text-first {
    position: absolute;
    top: 40px;
    left: 40px;
    max-width: 450px;

    @media (max-width: 899px) {
      top: 20px;
      left: 20px;
    }

    p {
      margin: 0;
      font-size: 34px;
      line-height: 130%;
      font-weight: 600;
      color: $white;

      @media (max-width: 899px) {
        font-size: 28px;
      }
    }

    strong {
      font-weight: 900;
      color: $colorAccent1;
    }
  }

  .image-text-secondary {
    position: absolute;
    top: 250px;
    left: 40px;
    max-width: 350px;

    @media (max-width: 899px) {
      top: 150px;
      left: 20px;
    }

    p {
      margin: 0;
      font-size: 26px;
      line-height: 130%;
      font-weight: 600;
      color: $white;

      @media (max-width: 899px) {
        font-size: 20px;
      }
    }
  }
}

.home-numbers {
  width: 100%;
  margin-top: -150px;
  padding: 0 0 50px;
  background: $dark;

  @media (max-width: 899px) {
    height: auto;
  }
}

.numbers-footer {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  padding-top: 50px;


  @media (max-width: 899px) {
    padding-top: 25px;
  }

  @media (max-width: 599px) {
    flex-direction: column;
  }

  .footer-item {
    width: calc((100% / 3) - (40px / 3));
    padding: 20px;
    border-radius: 4px;
    border: 1px solid #272c33;
    font-family: $fontTitle;
    text-align: center;
    background: #191c21;

    //@media (max-width: 899px) {
    //  max-width: 175px;
    //}

    @media (max-width: 599px) {
      width: 100%;
      margin: 0 auto 30px;
    }

    .item-number-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 0 10px;
      font-size: 56px;
      line-height: 130%;
      font-weight: 600;
      color: $white;

      @media (max-width: 899px) {
        font-size: 46px;
      }

      p {
        margin: 0;
      }
    }

    .item-label {
      margin: 0;
      font-size: 24px;
      line-height: 130%;
      font-weight: 600;
      color: $white;

      @media (max-width: 899px) {
        font-size: 20px;
      }
    }
  }
}

.home-trainings {
  width: 100%;
  padding: 100px 80px;
  background: $white;

  @media (max-width: 899px) {
    padding: 100px 40px;
  }

  @media (max-width: 599px) {
    padding: 100px 20px;
  }

  .trainings-title {
    margin: 0 0 30px;
    font-size: 50px;
    line-height: 160%;
    font-weight: 600;
    text-align: center;
    color: $dark;

    @media (max-width: 899px) {
      font-size: 40px;
      line-height: 160%;
    }
  }

  .trainings-tabs {
    display: flex;
    justify-content: center;
    align-items: stretch;
    margin-bottom: 50px;

    @media (max-width: 699px) {
      flex-direction: column;
      align-items: center;
    }

    .tabs-item {
      width: 100%;
      max-width: 300px;
      margin-right: 20px;
      padding: 15px;
      border: 1px solid transparent;
      border-radius: 50px;
      color: $dark;
      font-size: 22px;
      line-height: 130%;
      text-align: center;
      cursor: pointer;
      transition: .3s all ease;

      &:last-of-type {
        margin-right: 0;
      }

      @media (max-width: 899px) {
        max-width: 250px;
        font-size: 18px;
      }

      @media (max-width: 699px) {
        margin: 0 auto 20px;

        &:last-of-type {
          margin-right: auto;
        }
      }

      &.is-active {
        border-color: $dark;
        color: $white;
        background: $dark;
      }

      &:hover {
        border-color: $dark;
      }
    }

    .item-title {
      margin: 0 0 5px;

      strong {
        font-weight: 600;
      }
    }

    .item-label {
      margin: 0;
      font-style: italic;
    }
  }

  .flickity-viewport {
    width: 100%;
  }

  .flickity-prev-next-button {
    &.previous {
      left: -40px;

      @media (max-width: 899px) {
        left: -20px;
      }

      @media (max-width: 599px) {
        left: -10px;
      }
    }

    &.next {
      right: -40px;

      @media (max-width: 899px) {
        right: -20px;
      }

      @media (max-width: 599px) {
        right: -10px;
      }
    }
  }

  .trainings-tab-content {
    position: relative;
    display: none;

    &.is-active {
      display: block;
    }

    .trainings-flickity {
      position: relative;
      width: 100%;
    }

    .flickity-trainings-group {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      width: 100%;
    }

    .training-item {
      position: relative;
      width: calc((100% / 3) - (40px / 3));
      min-height: 300px;
      margin-right: 20px;
      margin-bottom: 20px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;

      &:nth-child(3n - 3) {
        margin-right: 0;
      }

      @media (max-width: 899px) {
        width: calc((100% / 2) - 20px);

        &:nth-child(3n - 3) {
          margin-right: 20px;
        }

        &:nth-child(2n - 2) {
          margin-right: 0;
        }
      }

      @media (max-width: 599px) {
        width: calc((100% / 2) - 10px);
        margin-right: 10px;
        margin-bottom: 10px;

        &:nth-child(3n - 3) {
          margin-right: 10px;
        }
      }
    }

    .item-tag {
      position: absolute;
      top: 0;
      left: 0;
      padding: 10px;
      color: $white;
      font-size: 16px;
      font-weight: 600;
      background: $colorAccent2;

      @media (max-width: 899px) {
        font-size: 14px;
      }

      @media (max-width: 599px) {
        font-size: 12px;
      }
    }

    .item-footer {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      min-height: 160px;
      padding: 15px;
      background: rgba($dark, .8);
    }

    .item-title {
      margin: 0 0 10px;
      color: $colorAccent1;
      font-weight: 500;
      font-size: 22px;
      line-height: 130%;

      @media (max-width: 899px) {
        font-size: 18px;
      }

      @media (max-width: 599px) {
        font-size: 16px;
      }
    }

    .item-text {
      margin: 0;
      min-height: 0;
      color: $white;
      font-size: 14px;
      line-height: 150%;

      @media (max-width: 599px) {
        font-size: 12px;
      }
    }
  }
}

.home-contact {
  width: 100%;
  padding: 50px 0;
  background: $dark;

  .contact-suptitle {
    margin: 0;
    color: $white;
    font-size: 28px;
    line-height: 130%;
    text-align: center;

    @media (max-width: 899px) {
      font-size: 24px;
    }
  }

  .contact-title {
    width: 100%;
    max-width: 800px;
    margin: 40px auto;
    font-size: 50px;
    color: $white;

    font-family: $fontTitle;
    font-weight: 500;
    text-align: center;

    @media (max-width: 899px) {
      font-size: 40px;
    }

    p {
      margin: 0;
    }

    strong {
      font-size: 54px;
      font-weight: 700;

      @media (max-width: 899px) {
        font-size: 46px;
      }
    }
  }

  .contact-form {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    width: 100%;
    max-width: 600px;
    margin: 0 auto;

    @media (max-width: 599px) {
      flex-direction: column;
    }

    .form-group {
      margin-bottom: 30px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    .site-btn-wrapper {
      display: flex;
      justify-content: center;
    }

    .site-btn {
      margin-top: 0;
    }
  }
}

.home-step-forward {
  width: 100%;
  padding: 50px 0;
  background: $white;

  .step-forward-title {
    margin: 0 auto 50px;
    font-size: 50px;
    font-weight: 600;
    color: $dark;
    text-align: center;

    @media (max-width: 899px) {
      font-size: 40px;
      line-height: 160%;
    }
  }

  .step-forward-content {
    position: relative;

    .content-image {
      width: 100%;
      max-width: 80%;

      @media (max-width: 899px) {
        max-width: 100%;
      }
    }

    .content-text {
      position: absolute;
      top: 50%;
      right: 100px;
      width: 100%;
      max-width: 550px;
      padding: 40px 30px;
      transform: translateY(-50%);
      background: $dark;

      @media (max-width: 899px) {
        position: relative;
        top: auto;
        right: auto;
        width: 100%;
        max-width: 100%;
        transform: none;
      }

      .text {
        color: $white;
        font-size: 28px;
        font-weight: 600;
        line-height: 150%;

        p {
          margin: 0;
        }

        strong {
          color: $colorAccent1;
          font-weight: 600;
        }
      }

      .link {
        display: inline-block;
        margin-top: 10px;
        color: $white;
        font-size: 16px;
        transition: .4s all ease;

        &:hover {
          color: $colorAccent1;
        }

        i {
          font-size: 14px;
        }
      }
    }
  }
}

.home-faq {
  width: 100%;
  padding: 55px 0 100px;
  background: $dark;

  .faq-title {
    margin: 0 0 80px;
    font-size: 50px;
    color: $white;
    font-weight: 600;
    text-align: center;

    @media (max-width: 899px) {
      font-size: 40px;
    }
  }

  .faq-container {
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
  }

  .faq-item {
    position: relative;
    overflow: hidden;
    margin-bottom: 40px;
    padding: 15px 20px;
    border-radius: 6px;
    border: 1px solid $white;
    font-size: 22px;
    color: $white;
    font-weight: 500;
    transition: .4s all ease;

    &:last-of-type {
      margin-bottom: 0;
    }

    @media (max-width: 899px) {
      font-size: 18px;
    }

    &.is-active {
      .item-header {
        position: relative;
        cursor: pointer;

        &::after {
          transform: translateY(-50%) rotate(-90deg);
        }
      }

      .item-content {
        max-height: 100vh;
      }
    }

    .item-header {
      position: relative;
      cursor: pointer;

      &::after {
        content: '\E826';
        position: absolute;
        top: 50%;
        right: 10px;
        font-family: fontello;
        font-size: 16px;
        transform: translateY(-50%) rotate(90deg);
        transition: .4s all ease;
      }

      span {
        width: 100%;
        padding-right: 20px;
      }
    }

    .item-content {
      position: relative;
      overflow: hidden;
      width: 100%;
      max-height: 0;
      font-size: 18px;
      line-height: 150%;
      transition: .6s all ease;

      @media (max-width: 899px) {
        font-size: 16px;
      }

      .content-separator {
        width: 100%;
        height: 1px;
        margin: 15px auto;
        background: $white;
      }

      p {
        margin: 0 0 10px;

        &:last-of-type {
          margin: 0;
        }
      }

      strong {
        color: $colorAccent1;
        font-weight: 600;
      }

      a {
        text-decoration: underline;
      }
    }
  }
}

.home-who-we-are {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 25px 0;
  background: $white;

  @media (max-width: 1023px) {
    flex-direction: column;
  }

  .who-we-are-container {
    position: relative;

    .who-we-are-image {
      width: 100%;
      max-width: 80%;

      @media (max-width: 899px) {
        max-width: 100%;
      }
    }
  }

  .who-we-are-title {
    font-size: 50px;
    color: $dark;
    font-weight: 600;
    text-align: center;

    @media (max-width: 899px) {
      font-size: 40px;
    }
  }

  .who-we-are-content {
    position: absolute;
    top: 50%;
    right: 100px;
    width: 100%;
    max-width: 1100px;
    color: $white;
    font-family: $fontText;
    font-size: 26px;
    font-weight: 500;
    line-height: 150%;
    transform: translateY(-50%);

    @media (max-width: 1299px) {
      right: 0;
    }

    @media (max-width: 999px) {
      position: relative;
      top: auto;
      right: auto;
      width: 100%;
      max-width: 100%;
      font-size: 22px;
      transform: none;
    }

    .who-we-are-text {
      padding: 40px 30px;
      background: $dark;
    }

    .cd-headline.slide {
      font-size: 44px;
      line-height: 130%;

      @media (max-width: 899px) {
        font-size: 34px;
      }
    }

    .cd-headline.slide span {
      padding: 0;
    }

    p {
      margin: 0;
      font-size: 24px;

      @media (max-width: 899px) {
        font-size: 22px;
      }
    }

    strong {
      color: $colorAccent1;
      font-weight: 600;
    }

    a {
      display: inline-block;
      margin-top: 10px;
      color: $white;
      font-size: 14px;
      transition: .4s all ease;

      &:hover {
        color: $colorAccent1;
      }

      i {
        font-size: 12px;
      }
    }
  }
}

.home-news {
  width: 100%;
  padding: 50px 0 150px;
  background: $dark;

  .news-title {
    margin: 0 auto 50px;
    font-size: 50px;
    color: $white;
    font-weight: 600;
    text-align: center;

    @media (max-width: 899px) {
      font-size: 40px;
    }
  }

  .articles-flickity {

    .flickity-viewport {
      width: 100%;
    }

    .flickity-prev-next-button.previous {
      left: -60px;

      @media (max-width: 1349px) {
        left: -40px;
      }

      @media (max-width: 899px) {
        left: -20px;
      }
    }

    .flickity-prev-next-button.next {
      right: -60px;

      @media (max-width: 1349px) {
        right: -40px;
      }

      @media (max-width: 899px) {
        right: -20px;
      }
    }

    .flickity-slider {
      display: flex;
      align-items: stretch;
    }

    .news-item {
      position: relative;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      width: calc((100% / 3) - 20px);
      min-height: 100%;
      margin-right: 30px;
      cursor: pointer;
      //
      //&:last-of-type {
      //  margin-right: 0;
      //}

      @media (max-width: 899px) {
        width: calc((100% / 2) - 10px);
      }

      @media (max-width: 599px) {
        width: 80%;
      }

      &:hover {
        .item-image {
          transform: scale(1.05);
        }
      }

      .item-image {
        min-height: 250px;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        transition: all .4s ease;
      }

      .item-details {
        position: relative;
        z-index: 1;
        flex: 1;
        display: flex;
        flex-direction: column;
        padding: 15px;
        background: #262626;
      }

      .item-title {
        flex: 1;
        margin: 0 0 10px;
        color: $white;
        font-size: 22px;
        font-weight: 500;
        line-height: 150%;

        @media (max-width: 899px) {
          font-size: 18px;
        }
      }

      .item-source {
        margin: 0 0 10px;
        color: $white;
        font-size: 14px;

        &:last-of-type {
          margin: 0;
        }

        @media (max-width: 899px) {
          font-size: 12px;
        }
      }
    }
  }
}
