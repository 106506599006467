// Generate Duration && Delay
// Default duration value: 1000
// Default delay value: 0

[data-aos] {
  transition-duration: .6s;

  &.aos-animate {
    transition-delay: 3ms;
  }
}


[data-aos] {
  @for $i from 1 through 60 {
    body[data-aos-duration='#{$i * 50}'] &,
    &[data-aos][data-aos-duration='#{$i * 50}'] {
      // transition-duration: #{$i * 50}ms;
      transition-duration: .6s;
    }

    body[data-aos-delay='#{$i * 50}'] &,
    &[data-aos][data-aos-delay='#{$i * 50}'] {
      transition-delay: 0s;

      &.aos-animate {
        transition-delay: #{$i * 50}ms;

      }
    }
  }
}

*[data-aos] {
  @media screen and (max-width: 699px) {
    transition-delay: 0s !important;
    &.aos-animate {
      transition-delay: 0ms !important;
    }
  }
}
