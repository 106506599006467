// Animations variables
$aos-distance: 50px !default;

// IS VISIBLE
// $easing : cubic-bezier(0.455, 0.03, 0.515, 0.955);
// $duration : .6s;
// $transform : translateY(50px);


/**
 * Fade animations:
 * fade
 * fade-up, fade-down, fade-left, fade-right
 * fade-up-right, fade-up-left, fade-down-right, fade-down-left
 */

[data-aos^='fade'][data-aos^='fade'] {
  opacity: 0;
  transition-property: opacity, transform;

  &.aos-animate {
    opacity: 1;
    transform: translate(0, 0);
  }
}

[data-aos='fade-up'] {
  transform: translate(0, $aos-distance);
}

[data-aos='fade-down'] {
  transform: translate(0, -$aos-distance);
}

[data-aos='fade-right'] {
  transform: translate(-$aos-distance, 0);
}

[data-aos='fade-left'] {
  transform: translate($aos-distance, 0);
}

[data-aos='fade-up-right'] {
  transform: translate(-$aos-distance, $aos-distance);
}

[data-aos='fade-up-left'] {
  transform: translate($aos-distance, $aos-distance);
}

[data-aos='fade-down-right'] {
  transform: translate(-$aos-distance, -$aos-distance);
}

[data-aos='fade-down-left'] {
  transform: translate($aos-distance, -$aos-distance);
}




/**
 * Zoom animations:
 * zoom-in, zoom-in-up, zoom-in-down, zoom-in-left, zoom-in-right
 * zoom-out, zoom-out-up, zoom-out-down, zoom-out-left, zoom-out-right
 */

[data-aos^='zoom'][data-aos^='zoom'] {
  opacity: 0;
  transition-property: opacity, transform;

  &.aos-animate {
    opacity: 1;
    transform: translate(0, 0) scale(1);
  }
}

[data-aos='zoom-in'] {
  transform: scale(.6);
}

[data-aos='zoom-in-up'] {
  transform: translate(0, $aos-distance) scale(.6);
}

[data-aos='zoom-in-down'] {
  transform: translate(0, -$aos-distance) scale(.6);
}

[data-aos='zoom-in-right'] {
  transform: translate(-$aos-distance, 0) scale(.6);
}

[data-aos='zoom-in-left'] {
  transform: translate($aos-distance, 0) scale(.6);
}

[data-aos='zoom-out'] {
  transform: scale(1.2);
}

[data-aos='zoom-out-up'] {
  transform: translate(0, $aos-distance) scale(1.2);
}

[data-aos='zoom-out-down'] {
  transform: translate(0, -$aos-distance) scale(1.2);
}

[data-aos='zoom-out-right'] {
  transform: translate(-$aos-distance, 0) scale(1.2);
}

[data-aos='zoom-out-left'] {
  transform: translate($aos-distance, 0) scale(1.2);
}




/**
 * Slide animations
 */

[data-aos^='slide'][data-aos^='slide'] {
  transition-property: transform;

  &.aos-animate {
    transform: translate(0, 0);
  }
}

[data-aos='slide-up'] {
  transform: translateY(100%);
}

[data-aos='slide-down'] {
  transform: translateY(-100%);
}

[data-aos='slide-right'] {
  transform: translateX(-100%);
}

[data-aos='slide-left'] {
  transform: translateX(100%);
}




/**
 * Flip animations:
 * flip-left, flip-right, flip-up, flip-down
 */

[data-aos^='flip'][data-aos^='flip'] {
  backface-visibility: hidden;
  transition-property: transform;
}

[data-aos='flip-left'] {
  transform: perspective(2500px) rotateY(-100deg);
  &.aos-animate {transform: perspective(2500px) rotateY(0);}
}

[data-aos='flip-right'] {
  transform: perspective(2500px) rotateY(100deg);
  &.aos-animate {transform: perspective(2500px) rotateY(0);}
}

[data-aos='flip-up'] {
  transform: perspective(2500px) rotateX(-100deg);
  &.aos-animate {transform: perspective(2500px) rotateX(0);}
}

[data-aos='flip-down'] {
  transform: perspective(2500px) rotateX(100deg);
  &.aos-animate {transform: perspective(2500px) rotateX(0);}
}

[data-aos-off-mobile] {
  @media screen and (max-width: 699px) {
    opacity: 1;
    transform: none;
    &.aos-animate {transform: none;}
  }
}




/**
 * Reveal animations:
 *
 */

[data-aos^='reveal'][data-aos^='reveal'] {
  position: relative;
  overflow: hidden;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: $white;

    transform: translate3d(0, 0, 0);
    transition: all 1s ease-out 0s;

    z-index: 9;
  }

  &.aos-animate {
    &:before {
      transform: translate3d(100%, 0, 0);
    }
  }
}

[data-aos^='reveal-dark'][data-aos^='reveal-dark'] {
  &:before {
    background: $dark;
  }
}

[data-aos='reveal-left'][data-aos='reveal-left'],
[data-aos='reveal-dark-left'][data-aos='reveal-dark-left'] {
  &.aos-animate {
    &:before {
      transform: translate3d(-100%, 0, 0);
    }
  }
}



/**
 * draw-line animations:
 *
 */

[data-aos^='draw-line'][data-aos^='draw-line'] {

  &:before {
    height: 0;
  }

  &.aos-animate {
    &:before {
      height: 100%;
      transition-duration: 4s;
    }
  }
}

[data-aos='reveal-left'][data-aos='reveal-left'] {
  &.aos-animate {
    &:before {
      transform: translate3d(-100%, 0, 0);
    }
  }
}




/**
 * draw-line animations:
 *
 */

[data-aos^='block-split-anim'][data-aos^='block-split-anim'] {

  &:before {
    right: 0;
    transition: all 1000ms ease-in-out 0ms;
  }

  .split-text {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
    transition: all 1000ms ease-in-out 500ms;
  }

  .anim-chunk {
    display: block;
    position: relative;
    overflow: hidden;

    .chunk-content {
      position: relative;
      top: 80px;

      transition: all 1000ms ease-in-out 500ms;
    }

    &.chunk2 {
      .chunk-content { transition-delay: 600ms; }
    }

    &.chunk3 {
      .chunk-content { transition-delay: 700ms; }
    }

    &.chunk4 {
      .chunk-content { transition-delay: 800ms; }
    }
  }
}

[data-aos='block-split-anim'][data-aos='block-split-anim'] {
  &.aos-animate {
    &:before {
      right: 50%;
    }

    .split-text {
      opacity: 1;
      transform: none;
    }

    .anim-chunk {
      .chunk-content {
        top: 0;
      }
    }
  }
}





[data-hover-animate-line-center] {
  position: relative;

  &:before,
  &:after {
    transition: all 0.45s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  }

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    right: 49%;
    z-index: 2;
    // width: calc(50% - 50px);
    width: 0px;
    height: 3px;
    background: $colorAccent1;
    transform: translate(0, 3px);
  }

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 49%;
    z-index: 2;
    // width: calc(50% - 50px);
    width: 0px;
    height: 3px;
    background: $colorAccent1;
    transform: translate(0, 3px);
  }

  &:hover {
    &:before,
    &:after {
      width: calc(50% - 35px);
    }
  }
}

[data-aos-animate-border-bottom] {
  position: relative;
  border-bottom-color: transparent !important;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
    // width: 100%;
    width: 0px;
    height: 3px;
    background: $colorAccent1;
    transform: translate(0, 2px);

    transition: all 0.45s cubic-bezier(0.455, 0.03, 0.515, 0.955) .65s;
  }

  &.aos-animate {
    &:before,
    &:after {
      width: 100%;
    }
  }
}

[data-aos-animate-border-left] {
  position: relative;
  border-left-color: transparent !important;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    // width: 100%;
    width: 3px;
    height: 0px;
    background: $colorAccent1;
    transform: translate(-4px, 0);

    transition: all 0.45s cubic-bezier(0.455, 0.03, 0.515, 0.955) .65s;
  }

  &.aos-animate {
    &:before,
    &:after {
      height: 100%;
    }
  }
}

[data-selected-animate-line-bottom] {
  position: relative;

  &:before,
  &:after {
    transition: all 0.45s cubic-bezier(0.455, 0.03, 0.515, 0.955);
  }

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    right: 50%;
    z-index: 2;
    // width: calc(50% - 50px);
    width: 0px;
    height: 3px;
    background: $colorAccent1;
    transform: translate(0, 0);
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    z-index: 2;
    // width: calc(50% - 50px);
    width: 0px;
    height: 3px;
    background: $colorAccent1;
    transform: translate(0, 0);
  }

  // &.is-selected,
  &.is-nav-selected {
    &:before,
    &:after {
      width: calc(50% - 25px);
    }
  }
}


.split001 .split001-text-wrapper .split001-content-container .split001-text[data-aos-small-line-effect]:after {
  right: 100% !important;
  transition: all 0.45s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0.24s;
}

.split001 .split001-text-wrapper .split001-content-container .split001-text[data-aos-small-line-effect].aos-animate:after {
  right: 80% !important;
}

.split001 .split001-text-wrapper .split001-content-container .split001-heading[data-aos-small-line-effect]:after {
  right: 100% !important;
  transition: all 0.45s cubic-bezier(0.455, 0.03, 0.515, 0.955) 0.24s;
}

.split001 .split001-text-wrapper .split001-content-container .split001-heading[data-aos-small-line-effect].aos-animate:after {
  right: 80% !important;
}

.blog-listing [data-aos-small-side-line] {
  .blog-item-title {
    border-left-color: transparent;

    position: relative;

    &:before,
    &:after {
      transition: all 0.45s cubic-bezier(0.455, 0.03, 0.515, 0.955) 1s;
    }

    &:before {
      content: '';
      position: absolute;
      bottom: 50%;
      // top: 0;
      left: 0;
      z-index: 2;
      // width: calc(50% - 50px);
      // width: 0px;
      width: 3px;
      height: 0px;
      background: $colorAccent1;
      transform: translate(-3px, 0);
    }

    &:after {
      content: '';
      position: absolute;
      top: 50%;
      // bottom: 0;
      left: 0;
      z-index: 2;
      // width: calc(50% - 50px);
      // width: 0px;
      width: 3px;
      height: 0px;
      background: $colorAccent1;
      transform: translate(-3px, 0);
    }
  }

  &.aos-animate {
    .blog-item-title {
      &:before,
      &:after {
        height: 50%;
      }
    }
  }
}
