// --------------------------------------------------
// Content
// --------------------------------------------------

.content {

}

// --------------------------------------------------
// Section Hero
// --------------------------------------------------

.section-hero {
  position: relative;
  width: 100%;
  height: 55vh;
  background: $colorAccent1;

  overflow: hidden;

  @media only screen and (max-width: 767px) {
    height: 305px;
  }

  .main-carousel {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #fff;

    .carousel-cell {
      position: relative;
      width: 100%;
      height: 100%;
      overflow: hidden;
    }

    .carousel-cell-content {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      padding: 45px;
      text-align: center;
      color: #fff;
      background-size: cover;
      background-position: center;

      opacity: 0;
      transition: all 0.45s ease;

      z-index: 4;

      overflow: hidden;

      &.has-effect-blue {
        &:before {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background: $colorAccent1;
          z-index: 5;
          opacity: .75;
        }
      }

      &.has-effect-grey {
        &:before {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%,  #546067 100%);
          background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%, #546067 100%);
          background: linear-gradient(to bottom, rgba(0,0,0,0) 0%, #546067 100%);
          z-index: 5;
          opacity: .75;
        }
      }

      * {
        position: relative;
        z-index: 6;
      }

      .separator-content-wrapper {
        height: 100%;
      }
    }

    .carousel-cell-title {
      position: absolute;
      top: 15px;
      left: 50%;
      transform: translate3d(-50%, 0, 0);
      min-width: 300px;
      font-family: $fontTitleSerif;
      font-size: 32px;
      color: #fff;
      letter-spacing: 0.055em;

      @media only screen and (max-width: 1099px) {
        top: 0;
        font-size: 22px;
      }

      @media only screen and (max-width: 767px) {
        font-size: 16px;
      }
    }

    .carousel-cell-text {
      position: absolute;
      top: 50%;
      left: 50%;
      right: 0;
      transform: translate3d(-50%, -50%, 0);

      width: 100%;
      max-width: 850px;
      min-width: 300px;
      margin: 0;
      padding: 55px 0;
      font-family: $fontTextLight;
      font-size: 26px;
      line-height: 1.5;
      letter-spacing: 0.05em;
      color: #fff;
      text-align: center;
      // text-shadow: 0 1px 10px rgba(0, 0, 0, .35);

      @media only screen and (max-width: 1349px) {
        font-size: 24px;
        line-height: 1.3;
      }

      @media only screen and (max-width: 1099px) {
        font-size: 20px;
        line-height: 1.2;
      }

      @media only screen and (max-width: 767px) {
        font-size: 18px;
        padding: 35px;
      }

      @media only screen and (max-width: 699px) {
        font-size: 14px;
      }
    }

    .site-btn {
      position: absolute;
      bottom: 75px;
      left: 50%;
      transform: translate3d(-50%, 0, 0);

      padding: 25px 45px;

      color: #fff;
      border-color: #fff;
      font-size: 14px;

      font-family: $fontTitleSerif;
      letter-spacing: 0.055em;
      text-transform: uppercase;

      @media only screen and (max-width: 1349px) {
        bottom: 25px;
      }

      @media only screen and (max-width: 1099px) {
        bottom: 0;
      }

      @media only screen and (max-width: 767px) {
        padding: 15px 25px;
        font-size: 12px;
      }

      @media only screen and (max-width: 699px) {
        padding: 15px 15px;
      }

      &:hover {
        background: #fff;
        color: $colorAccent1;
      }
    }

    img.main {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      display: block;
      width: auto;
      height: 100%;
    }
  }

  &.no-text {
    .main-carousel .carousel-cell-title {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
      min-width: 300px;
      margin: 0;
      padding: 0;
      font-family: $fontTitleSerif;
      font-size: 32px;
      color: #fff;
      letter-spacing: 0.055em;

      @media only screen and (max-width: 1099px) {
        font-size: 22px;
      }

      @media only screen and (max-width: 767px) {
        font-size: 16px;
      }
    }

    .carousel-cell-text {
      display: none;
    }
  }

  img.hero-pattern {
    position: absolute;
    top: auto;
    left: auto;
    left: 0;
    bottom: 0;
    height: auto;
    filter: none;
    z-index: 4;

    @media only screen and (max-width: 1099px) {
      width: 110px;
    }

    @media only screen and (max-width: 699px) {
      display: none;
    }
  }
}
