// --------------------------------------------------
// Header
// --------------------------------------------------

.header {
  position: relative;
  left: 0;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  width: 100%;
  height: 80px;
  padding: 0 80px;
  z-index: 50;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);

  @media (max-width: 1024px) {
    padding: 0 40px;
  }

  @media (max-width: 768px) {
    padding: 30px 20px;
  }

  .header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .header-logo {
    img {
      height: 40px;
    }
  }

  .header-nav {
    display: flex;
    align-items: center;

    @media (max-width: 899px) {
      display: none;
    }

    .header-link {
      position: relative;
      font-size: 18px;
      line-height: 1;
      font-weight: 600;
      color: $colorAccent2;
      text-transform: uppercase;
      margin: 0 20px;
      transition: all 0.35s ease;

      &::before {
        content: '';
        position: absolute;
        bottom: -32px;
        left: 0;
        width: 100%;
        height: 4px;
        transform-origin: center;
        transform: scaleX(0);
        transition: all .4s ease;
        background: $colorAccent1;
      }

      &:hover {
        color: $colorAccent1;

        &::before {
          transform: scaleX(1);
        }
      }

      @media (max-width: 1024px) {
        font-size: 16px;
        margin: 0 10px;
      }

      @media (max-width: 768px) {
        font-size: 14px;
      }
    }
  }

  .header-right {
    display: flex;
    align-items: center;

    .header-img {
      display: block;
      width: 150px;
      margin: 0 20px 0 0;

      @media (max-width: 767px) {
        width: 100px;
      }

      img {
        display: block;
        width: 100%;
      }
    }

    .header-language {
      position: relative;
    }

    .language-link {
      display: flex;
      align-items: center;
      font-size: 18px;
      line-height: 1;
      font-weight: 600;
      color: $colorAccent2;
      cursor: pointer;

      svg {
        margin-left: 10px;
      }

      &:hover + .language-opts {
        display: block;
      }

      @media (max-width: 1024px) {
        font-size: 16px;
      }

      @media (max-width: 768px) {
        font-size: 14px;
      }
    }

    .language-opts {
      position: absolute;
      bottom: -115px;
      left: 50%;
      z-index: 20;
      display: none;
      padding: 20px;
      transform: translateX(-50%);

      &:hover {
        display: block;
      }

      .opts-links {
        padding: 20px;
        background: $white;
        box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
      }

      a {
        position: relative;
        display: inline-block;
        font-size: 18px;
        line-height: 1;
        font-weight: 600;
        color: $colorAccent2;
        margin-bottom: 20px;
        transition: .6s all ease;

        &:last-of-type {
          margin-bottom: 0;
        }

        &::before {
          content: '';
          position: absolute;
          bottom: -10px;
          left: 0;
          width: 100%;
          height: 4px;
          transform-origin: center;
          transform: scaleX(0);
          transition: all .4s ease;
          background: $colorAccent1;
        }

        &:hover {
          color: $colorAccent1;

          &::before {
            transform: scaleX(1);
          }
        }

        @media (max-width: 1024px) {
          font-size: 16px;
        }

        @media (max-width: 768px) {
          font-size: 14px;
        }
      }
    }

    .site-btn {
      font-family: $fontText;
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 140%;
      text-align: center;
      text-transform: uppercase;
      color: $white;
      margin: 0 20px 0 0;

      @media (max-width: 1024px) {
        font-size: 16px;
      }

      @media (max-width: 768px) {
        font-size: 14px;
      }
    }
  }

  .header-mobile-trigger {
    display: none;
    width: 35px;
    height: 40px;
    margin-left: 20px;
    position: relative;
    transform: rotate(0deg);
    transition: 0.5s ease-in-out;
    cursor: pointer;

    @media (max-width: 899px) {
      display: block;
    }

    //.burger-overlay {
    //  position: absolute;
    //  right: 0;
    //  left: 0;
    //  bottom: 0;
    //  top: 0;
    //  z-index: 20;
    //}

    &:hover {
      span {
        &:nth-of-type(4) {
          width: 100%;
        }
      }
    }

    span {
      display: block;
      position: absolute;
      height: 3px;
      width: 100%;
      background: $colorAccent1;
      opacity: 1;
      left: 0;
      transform: rotate(0deg);
      transition: 0.25s ease-in-out;

      &:nth-of-type(1) {
        top: 7px;
      }

      &:nth-of-type(2),
      &:nth-of-type(3) {
        top: 18px;
      }

      &:nth-of-type(4) {
        top: 29px;
        width: 50%;
        right: 0;
        left: auto;
      }
    }

    &.is-active {
      span {
        &:nth-of-type(1) {
          top: 18px;
          width: 0;
          left: 50%;
        }

        &:nth-of-type(2) {
          transform: rotate(45deg);
        }

        &:nth-of-type(3) {
          transform: rotate(-45deg);
        }

        &:nth-of-type(4) {
          top: 18px;
          width: 0;
          left: 50%;
        }
      }
    }
  }
}

.header-mobile {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 40;
  display: none;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100vh);
  background: $white;
  transform: translateX(101%);
  transform-origin: left;
  transition: all 0.6s ease;
  opacity: 0;

  @media (max-width: 899px) {
    display: flex;
  }

  &.is-active {
    transform: translateX(0);
    opacity: 1;
  }

  .header-link {
    display: block;
    margin-bottom: 40px;
    font-size: 22px;
    line-height: 1;
    font-weight: 600;
    text-align: center;
    color: $colorAccent2;
    transition: all .4s ease;

    &:hover {
      color: $colorAccent1;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}
