// Footer
.footer {
  background-color: $colorAccent1;
  padding: 20px 80px;

  @media (max-width: 1024px) {
    padding: 20px 40px;
  }

  @media (max-width: 768px) {
    padding: 20px;
  }

  p {
    margin: 0;
  }

  span {
    margin: 0 10px;
  }

  .footer-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    font-family: $fontText;
    color: $white;
    font-weight: 500;
    font-size: 16px;

    @media (max-width: 768px) {
      flex-direction: column-reverse;
      font-size: 14px;
    }
  }

  .footer-left {
    @media (max-width: 768px) {
      margin-top: 20px;
    }
  }

  .footer-socials,
  .footer-right {
    display: flex;
    align-items: center;
  }

  .social-link {
    margin-right: 10px;

    &:last-of-type {
      margin-right: 0;
    }

    img {
      width: 20px;

      @media (max-width: 768px) {
        width: 18px;
      }
    }
  }

  .footer-website {
    a {
      color: $white;
      font-weight: 600;
    }
  }
}
