// --------------------------------------------------
// Main Grid Components
// --------------------------------------------------
.site-container {
  position: relative;
  overflow: hidden;
  min-height: 100vh;
}

.site-grid {
  position: relative;
  overflow: hidden;
}

.site-block-ptpb {
  padding-top: 100px;
  padding-bottom: 100px;

  @media only screen and (max-width: 1349px) {
    padding-top: 75px;
    padding-bottom: 75px;
  }

  @media only screen and (max-width: 1099px) {
    padding-top: 55px;
    padding-bottom: 55px;
  }

  @media only screen and (max-width: 767px) {
    padding-top: 45px;
    padding-bottom: 45px;
  }
}

.site-max-width {
  position: relative;
  width: 100%;
  max-width: calc(1110px + (165px * 2));
  margin: 0 auto;
  padding-left: 165px;
  padding-right: 165px;

  &.overflow-visible {
    overflow: visible;
  }

  @media only screen and (max-width: 1349px) {
    // padding: 75px 45px;
    padding-left: 45px;
    padding-right: 45px;
  }

  @media only screen and (max-width: 1099px) {
    // padding: 75px 25px;
    padding-left: 25px;
    padding-right: 25px;
  }

  @media only screen and (max-width: 767px) {
    // padding: 25px 20px;
    padding-left: 20px;
    padding-right: 20px;
  }
}

.main-content {
  width: 100%;
}



















//
//
// DEPRECATED
//
//
.main-aside {

}

.has-aside .main {
  position: relative;
  width: 100%;
  padding-left: 300px;
  -webkit-transition: all .35s ease;
  transition: all .35s ease;

  & > section {
    border-left: 1px solid $lighter;
  }

  @media screen and (max-width: 1204px) {
    padding-left: 0;

    & > section {
      border-left: none;
    }
  }
}

@media screen and (max-width: 1204px) {
  .main-aside.show + .main {
    -webkit-transform: translate(300px);
    -ms-transform: translate(300px);
    transform: translate(300px);
  }
}



// --------------------------------------------------
// With Aside
// --------------------------------------------------
.has-aside {

}

.is-aside-active {

}

.init-grid {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  @media only screen and (max-width: 767px) {
    flex-wrap: wrap;
  }

  .col {
    width: 50%;

    @media only screen and (max-width: 767px) {
      width: 100%;
    }
  }

  .col-left {
    padding-right: 30px;

    @media (max-width: 767px) {
      padding: 0;
    }
  }

  .col-right {
    padding-left: 30px;

    @media (max-width: 767px) {
      padding: 0;
    }
  }
}
