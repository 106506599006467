.terms-wrapper {
  padding: 80px 20px;

  @media (max-width: 767px) {
    padding: 40px 20px;
  }

  .terms-content {
    @extend .regular-text;

    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
    color: $black;

    a.site-btn {
      display: inline-block;
      margin-bottom: 40px;
      padding: 15px 25px;
      border: 1px solid $colorAccent1;
      background: rgba($colorAccent1, 1);
      color: $white;
      font-family: $fontText;
      font-size: 20px;
      line-height: 1;
      font-weight: 600;
      text-align: center;
      cursor: pointer;
      transition: .3s;
      text-decoration: none;
      transition-duration: .3s;
      border-radius: 3px;
      -webkit-appearance: none;

      &:hover {
        background: rgba($colorAccent1, .9);
      }

      @media (max-width: 899px) {
        font-size: 16px;
      }
    }
  }

  p {
    @extend .regular-text;

    margin: 0 0 20px;
    font-weight: 500;
  }

  b,
  strong {
    font-weight: 700;
  }

  h1 {
    @extend .big-title;
  }

  h2, h3, h4, h5, h6 {
    @extend .medium-title;
  }

  h1, h2, h3, h4, h5, h6 {
    margin: 0 0 40px;
    color: $colorAccent1;
  }

  ul {
    @extend .regular-text;

    margin: 0 0 40px;
    padding: 0;
    list-style: none;

    li {
      position: relative;
      padding-left: 30px;

      &:before {
        position: absolute;
        content: '';
        left: 0;
        top: 8px;
        width: 6px;
        height: 6px;
        border-radius: 11px;
        background: $colorAccent1;
      }
    }
  }

  img {
    margin: 0 auto 40px;
    max-width: 100%;
  }

  a {
    text-decoration: underline;
    color: $colorAccent1;
  }
}
