// --------------------------------------------------
// Blocks
// --------------------------------------------------

.flickity-viewport {
  width: 100%;
}

.site-block {
  margin-bottom: 75px;
  padding: 0 80px;

  @media (max-width: 1024px) {
    padding: 0 40px;
  }

  @media (max-width: 768px) {
    margin-bottom: 50px;
    padding: 0 20px;
  }
}

.generic-block {
  .hero {
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: calc(100vh - 80px);
    margin-bottom: 100px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    @media (max-width: 768px) {
      height: auto;
      margin-bottom: 50px;
    }

    &.has-overlay {
      &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(270deg, #28BEF5 0%, #62C9EF 45.31%, rgba(196, 196, 196, 0) 100%);

        @media (max-width: 768px) {
          background: linear-gradient(0, #28BEF5 0%, #62C9EF 45.31%, rgba(196, 196, 196, 0) 100%);
        }
      }
    }

    &:not(.has-overlay) {
      background-position: center;
      background-repeat: repeat;

      .hero-right {
        width: 55%;
        justify-content: flex-start;
        padding: 20px;
        background: rgba($white, .7);

        @media (max-width: 768px) {
          width: 100%;
        }
      }

      .hero-content {
        padding-left: 0;

        @media (max-width: 768px) {
          padding-left: 0;
        }
      }
    }

    &.has-360 {
      &::before {
        left: auto;
        width: 50%;
        background: linear-gradient(270deg, #28BEF5 0%, #62C9EF 45.31%, rgba(196, 196, 196, 0) 100%);

        @media (max-width: 768px) {
          top: auto;
          left: 0;
          width: 100%;
          height: 50%;
          background: linear-gradient(0, #28BEF5 0%, #62C9EF 45.31%, rgba(196, 196, 196, 0) 100%);
        }
      }

      .hero-right {
        width: 50%;

        @media (max-width: 768px) {
          width: 100%;
        }
      }
    }

    .hero-container {
      position: relative;
      z-index: 1;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 100%;
      //max-width: 1360px;
      height: 100%;
      margin: 0 auto;
      padding: 0 120px;

      @media (max-width: 1024px) {
        padding: 0 40px;
      }

      @media (max-width: 768px) {
        flex-direction: column;
        align-items: flex-end;
        height: calc(100vh - 80px);
        padding: 40px 20px;
      }
    }

    .hero-visual {
      display: block;
      width: 400px;
      margin: 0 auto;

      @media (max-width: 1080px) {
        width: 350px;
      }

      @media (max-width: 768px) {
        width: 280px;
        margin: 0 0 50px;
      }
    }

    .orbitvu-viewer {
      max-width: 400px;
      margin: 0 auto;
      transform: scale(2.5);

      @media (max-width: 1080px) {
        max-width: 250px;
      }

      @media (max-width: 768px) {
        margin: 50px 0 100px;
      }

      .ovvvport.zoomin,
      .orbitvu-viewer-object-canvas.zindexer {
        background: transparent !important;
      }
    }

    .hero-right {
      display: flex;
      justify-content: flex-end;
      width: 65%;

      @media (max-width: 768px) {
        width: 100%;
      }
    }

    .hero-content {
      position: relative;
      z-index: 5;
      padding-left: 80px;

      @media (max-width: 768px) {
        padding-left: 0;
      }
    }

    .hero-title {
      margin: 0 0 30px;
      font-family: $fontTitle;
      font-style: normal;
      font-weight: bold;
      font-size: 50px;
      line-height: 63px;
      text-transform: uppercase;
      color: $colorAccent1;

      @media (max-width: 1299px) {
        font-size: 44px;
        line-height: 58px;
      }

      @media (max-width: 768px) {
        font-size: 40px;
        line-height: 53px;
      }
    }

    .hero-text {
      max-width: 750px;
      margin: 0;
      font-family: $fontText;
      font-style: normal;
      font-weight: bold;
      font-size: 22px;
      line-height: 140%;
      color: $colorAccent1;

      @media (max-width: 1080px) {
        font-size: 18px;
      }

      @media (max-width: 768px) {
        font-size: 16px;
      }
    }

    .site-btn {
      margin-top: 30px;
    }
  }

  .newsletter {
    .big-title {
      margin-bottom: 35px;
    }

    .regular-text,
    .site-btn-wrapper {
      text-align: center;
    }
  }

  .split-image-text {
    width: 100%;

    &.small-visual {
      .split-image-text-visual {
        max-width: 450px;
        margin: 0 auto;
      }
    }

    .split-image-text-title {
      margin-bottom: 35px;
    }

    .split-image-text-container {
      display: flex;
      align-items: stretch;

      @media (max-width: 768px) {
        flex-direction: column;
      }

      &.is-reverse {
        flex-direction: row-reverse;

        @media (max-width: 768px) {
          flex-direction: column-reverse;
        }

        .split-image-text-content {
          padding-left: 0;
          padding-right: 35px;

          @media (max-width: 768px) {
            margin-top: 0;
            margin-bottom: 35px;
            padding-right: 0;
          }
        }
      }
    }

    .split-image-text-left,
    .split-image-text-right {
      width: 50%;

      @media (max-width: 768px) {
        width: 100%;
      }
    }

    .split-image-text-visual {
      display: block;
      width: 100%;
    }

    video.split-image-text-visual {
      border: 2px solid $colorAccent1;
    }

    .split-image-text-content {
      padding-left: 35px;

      @media (max-width: 768px) {
        margin-top: 35px;
        padding-left: 0;
      }

      h1, h2, h3 {
        @extend .medium-title;
        margin: 0 0 30px;
      }

      p,
      ul li {
        @extend .regular-text;
      }

      .site-btn {
        margin-top: 30px;
      }
    }
  }

  .introduction {
    .introduction-text {
      width: 100%;
      //max-width: 75%;

      h1, h2, h3 {
        @extend .medium-title;
        margin: 0 0 30px;
      }

      p {
        @extend .regular-text;
      }

      .site-btn {
        margin-top: 30px;
      }
    }
  }

  .three-columns {
    width: 100%;

    .three-columns-title {
      margin-bottom: 35px;
    }

    .three-columns-container {
      display: flex;
      align-items: stretch;

      @media (max-width: 1399px) {
        flex-wrap: wrap;
      }

      @media (max-width: 768px) {
        flex-direction: column;
      }
    }

    .three-columns-left {
      width: calc(100% / 3);

      @media (max-width: 1399px) {
        width: 50%;
      }

      @media (max-width: 768px) {
        width: 100%;
      }

      .three-columns-content {
        width: 100%;
      }
    }

    .three-columns-middle {
      width: calc(100% / 3);
      background: rgba($colorAccent2, .25);

      @media (max-width: 1399px) {
        width: 50%;
      }

      @media (max-width: 768px) {
        width: 100%;
      }

      .three-columns-content {
        width: 100%;
      }
    }

    .three-columns-right {
      width: calc(100% / 3);

      @media (max-width: 1399px) {
        width: 100%;
      }

      .three-columns-content {
        width: 100%;
      }
    }

    .three-columns-content {
      position: relative;
      height: 100%;
      padding: 20px 20px 90px;

      video {
        display: block;
        width: 100%;
        margin: 0 0 30px;
      }

      figure,
      img {
        display: block;
        width: 100%;
        margin: 0;

        &.is-icon {
          width: auto;
        }
      }

      h1, h2, h3 {
        @extend .medium-title;
        margin: 0 0 30px;
      }

      p, ul, ul li {
        @extend .regular-text;
      }

      .is-aligned {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 30px;

        h1, h2, h3 {
          margin: 0 0 0 15px;
        }
      }

      .site-btn {
        position: absolute;
        left: 50%;
        bottom: 20px;
        display: flex;
        justify-content: center;
        transform: translateX(-50%);
      }
    }
  }

  .multi-columns {
    width: 100%;

    .multi-columns-title {
      margin-bottom: 35px;
    }

    .multi-columns-container {
      display: flex;
      align-items: stretch;

      @media (max-width: 1399px) {
        flex-wrap: wrap;
      }

      @media (max-width: 768px) {
        flex-direction: column;
      }
    }

    .multi-columns-col {
      margin-right: 30px;

      &:last-of-type {
        margin-right: 0;
      }

      &.col-1 {
        width: 100%;
        margin-right: 0;

        @media (max-width: 1399px) {
          width: 100%;
        }

        @media (max-width: 768px) {
          width: 100%;
        }
      }

      &.col-2 {
        width: calc(50%- 15px);

        @media (max-width: 768px) {
          width: 100%;
        }
      }

      &.col-3 {
        width: calc((100% / 3) - (60px / 3));

        @media (max-width: 1399px) {
          width: 50%;

          &:nth-of-type(2n + 2) {
            margin-right: 0;
          }
        }

        @media (max-width: 768px) {
          width: 100%;
        }
      }

      &.col-4 {
        width: calc((100% / 4) - (90px / 4));

        @media (max-width: 1399px) {
          width: calc(50% - 15px);
          margin-bottom: 30px;

          &:nth-of-type(2n + 2) {
            margin-right: 0;
          }
        }

        @media (max-width: 768px) {
          width: 100%;
          margin-bottom: 30px;
        }
      }


    }

    .multi-columns-content {
      position: relative;
      height: 100%;
      padding: 20px 20px 125px;

      iframe, video {
        display: block;
        width: 100%;
        margin: 0 0 30px;
      }

      figure,
      img {
        display: block;
        width: 100%;
        margin: 0;
      }

      h1, h2, h3 {
        @extend .medium-title;
        margin: 0 0 30px;
      }

      p {
        @extend .regular-text;
      }

      .site-btn {
        position: absolute;
        left: 50%;
        bottom: 20px;
        display: flex;
        justify-content: center;
        transform: translateX(-50%);
      }
    }
  }

  .webinar-wp {
    width: 100%;

    .webinar-wp-title {
      margin-bottom: 35px;
    }

    .webinar-wp-container {
      display: flex;
      align-items: stretch;

      @media (max-width: 899px) {
        flex-direction: column;
      }
    }

    .webinar-wp-left,
    .webinar-wp-right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 50%;

      @media (max-width: 899px) {
        width: 100%;
        flex-direction: row;
      }

      @media (max-width: 699px) {
        flex-direction: column;
      }
    }

    iframe {
      width: 100%;
      height: 400px;
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    }

    img {
      display: block;
      width: 100%;
      max-width: 350px;
      margin: 0 auto;
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    }

    .webinar-wp-right {
      position: relative;

      @media (max-width: 899px) {
        flex-direction: row-reverse;
        margin-top: 50px;
      }

      @media (max-width: 699px) {
        flex-direction: column;
      }

      .webinar-wp-content {
        position: relative;
        z-index: 1;
        padding-left: 35px;

        @media (max-width: 899px) {
          margin: 0;
          padding-left: 0;
          padding-right: 35px;
        }

        @media (max-width: 699px) {
          margin: 35px 0 0;
          padding-right: 0;
        }
      }
    }

    .webinar-wp-content {
      width: 100%;
      margin: 35px 0 0;

      @media (max-width: 899px) {
        margin: 0;
        padding-left: 35px;
      }

      @media (max-width: 699px) {
        margin: 35px 0 0;
        padding-left: 0;
      }

      h1, h2, h3 {
        @extend .medium-title;
        margin: 0 0 30px;
        color: $colorAccent2;
      }

      p {
        @extend .regular-text;
      }
    }
  }

  .products {
    .products-intro {
      width: 100%;
      margin: 35px 0 0;

      @media (max-width: 899px) {
        margin: 0;
        padding-left: 35px;
      }

      @media (max-width: 699px) {
        margin: 35px 0 0;
        padding-left: 0;
      }

      h1, h2, h3 {
        @extend .medium-title;
        margin: 0 0 30px;
        color: $colorAccent2;
      }

      p {
        @extend .regular-text;
      }

      strong, b {
        font-weight: bold;
      }
    }

    .products-slider {
      display: flex;
      align-items: stretch;
      margin-top: 35px;
      margin-bottom: 50px;
    }

    .product {
      width: 100%;
      max-width: 400px;
      min-height: 100%;
      margin-right: 35px;
      border: 1px solid $colorAccent2;

      .product-header {
        width: 400px;
        height: 400px;
        border-bottom: 1px solid $colorAccent2;

        img {
          display: block;
          width: 100%;
        }
      }

      .product-details {
        padding: 35px;

        @media (max-width: 768px) {
          padding: 15px;
        }

        p {
          @extend .regular-text;

          margin: 0;
        }

        .product-description {
          margin-top: 20px;
          //margin-bottom: 20px;
        }
      }
    }

    .products-related-title {
      color: $colorAccent2;
    }

    .site-btn-wrapper {
      width: 100%;
      margin-top: 30px;
      text-align: center;
    }

    .products-related {
      display: flex;
      justify-content: center;
      align-items: stretch;
      flex-wrap: wrap;
      margin-top: 25px;
    }

    .product-related {
      width: calc((100% / 4) - (105px / 4));
      margin-right: 35px;

      &:last-of-type {
        margin-right: 0;
      }

      @media (max-width: 1024px) {
        width: calc((100% / 2) - (35px / 2));
        margin-bottom: 35px;

        &:nth-of-type(2n + 2) {
          margin-right: 0;
        }
      }

      @media (max-width: 499px) {
        width: 100%;
        margin-right: 0;
      }

      .product-related-visual {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 200px;
        height: 200px;
        margin: 0 auto;
        border-radius: 50%;
        border: 2px solid $colorAccent1;

        img {
          display: block;
          width: 75%;
        }
      }

      .product-related-title {
        margin-top: 20px;
        text-align: center;
      }
    }
  }

  .sliders {
    .sliders-intro {
      width: 100%;
      margin: 35px 0 0;

      @media (max-width: 899px) {
        margin: 0;
        padding-left: 35px;
      }

      @media (max-width: 699px) {
        margin: 35px 0 0;
        padding-left: 0;
      }

      h1, h2, h3 {
        @extend .medium-title;
        margin: 0 0 30px;
        color: $colorAccent2;
      }

      p {
        @extend .regular-text;
      }

      strong, b {
        font-weight: bold;
      }
    }

    .sliders-slider {
      display: flex;
      align-items: stretch;
      margin-top: 35px;
      margin-bottom: 50px;
    }

    .slider {
      width: 100%;
      max-width: 400px;
      min-height: 100%;
      margin-right: 35px;

      .slider-header {
        position: relative;
        overflow: hidden;
        width: 400px;
        height: 300px;
        //border-bottom: 1px solid $colorAccent2;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }

      .slider-details {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 300px;
        padding: 35px;
        border: 1px solid $colorAccent2;

        @media (max-width: 768px) {
          padding: 15px;
        }

        .slider-title {
          text-align: center;
        }

        p {
          @extend .regular-text;

          margin: 0;
        }
      }
    }
  }

  .form {
    width: 100%;

    .form-title {
      margin-bottom: 35px;
    }

    .form-intro {
      //max-width: 75%;

      h1, h2, h3 {
        @extend .medium-title;
        margin: 0 0 30px;
        color: $colorAccent2;
      }

      p {
        @extend .regular-text;
      }
    }

    .form-request {
      width: 100%;
      max-width: 680px;
      margin: 50px auto 0;
    }

    .form-btn-wrapper {
      width: 100%;
      text-align: center;
    }
  }
}
