.mec {
  .generic-block {
    .product {
      width: 100%;
      max-width: 350px;
      min-height: 100%;
      margin-right: 35px;
      border: 1px solid $colorAccent2;

      .product-header {
        width: 250px;
        height: 250px;
        margin: 0 auto;
        border-bottom: 1px solid $colorAccent2;

        img {
          display: block;
          width: 100%;
        }
      }

      .medium-title {
        font-size: 24px;
        text-align: center;

        @media (max-width: 768px) {
          font-size: 18px;
        }
      }

      .product-details {
        padding: 35px;

        @media (max-width: 768px) {
          padding: 15px;
        }

        p {
          @extend .regular-text;

          margin: 0;
        }

        .product-description {
          margin-top: 20px;
          //margin-bottom: 20px;
        }
      }
    }
  }
}
