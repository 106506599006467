.alert-success-wrapper {
  position: relative;
  overflow: hidden;
  min-height: 0;
  opacity: 0;
  transition: all .6s ease;

  &.is-active {
    min-height: auto;
    opacity: 1;

    > div {
      display: block;
    }
  }

  > div {
    display: none;
  }
}

.alert-success {
  padding: 20px 20px 40px;
  color: $colorAccent1;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
}

.form-radio {
  [type="radio"]:checked,
  [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
  }

  [type="radio"]:checked + label,
  [type="radio"]:not(:checked) + label {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
  }

  [type="radio"]:checked + label:before,
  [type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid $colorAccent1;
    border-radius: 100%;
    background: #fff;
  }

  [type="radio"]:checked + label:after,
  [type="radio"]:not(:checked) + label:after {
    content: '';
    width: 12px;
    height: 12px;
    background: $colorAccent1;
    position: absolute;
    top: 4px;
    left: 4px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }

  [type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  [type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.form-checkbox {
  width: 100%;
  margin-bottom: 30px;
}

.form-checkbox input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.form-checkbox label {
  position: relative;
  cursor: pointer;
}

.form-checkbox label:before {
  content: '';
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid $colorAccent1;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 10px;
  display: inline-block;
  position: relative;
  border-radius: 3px;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
}

.form-checkbox input:checked + label:after {
  content: '';
  display: block;
  position: absolute;
  top: 2px;
  left: 7px;
  width: 6px;
  height: 14px;
  border: solid $colorAccent1;
  border-width: 0 2px 2px 0;
  transform: scale(0.8) rotate(45deg);
}

.form-row {
  display: flex;
  width: 100%;
}

.form-group {
  width: calc((100% / 3) - (70px / 3));
  margin-right: 35px;
  margin-bottom: 35px;

  &:nth-of-type(3n + 3) {
    margin-right: 0;
  }

  &.is-half {
    width: calc(50% - (35px / 2));

    &:nth-of-type(3n + 3) {
      margin-right: 35px;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }

  &.is-full {
    width: 100%;
    margin-right: 0;
  }
}

.form-input {
  width: 100%;
  padding: 15px;
  border: 1px solid $colorAccent1;
  border-radius: 3px;
  color: $dark;
  font-size: 20px;
  resize: none;

  &:focus {
    outline: 0;
  }

  @media (max-width: 899px) {
    font-size: 16px;
  }
}

.form-dynamic {
  position: absolute;
  top: -5000px;
  left: -5000px;
  opacity: 0;

  &.is-active {
    position: relative;
    top: auto;
    left: auto;
    opacity: 1;
  }
}

.pristine-error {
  margin-top: 10px;
  color: $colorAccent1;
  font-size: 14px;
  font-weight: 500;
}
