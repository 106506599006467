.popup-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 90;
  display: none;
  background: rgba($black, .75);
  cursor: pointer;

  &.is-active {
    display: block;
  }
}

.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 100;
  overflow: hidden;
  display: none;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  border-radius: 6px;
  background: $dark;
  transform: translate(-50%, -50%);

  @media (max-width: 640px) {
    width: calc(100% - 40px);
  }

  &.is-active {
    display: block;
  }

  .popup-container {
    position: relative;
    overflow: hidden;
    width: 100%;
  }

  .popup-close {
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 10;
    display: inline-block;

    svg {
      width: 26px;
    }

    path {
      fill: $white;
    }
  }

  .popup-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 60px 40px;
    background: $dark;

    svg {
      width: 200px;
    }

    path {
      fill: $white;
    }

    p {
      margin: 20px 0 0;
      font-size: 26px;
      color: $white;
      font-weight: 600;
      text-align: center;

      @media (max-width: 899px) {
        font-size: 20px;
      }

      span {
        color: $colorAccent1;
      }
    }
  }
}
